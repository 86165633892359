// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAdmins = createAsyncThunk(
    'admins/getAdmins',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/users?role=ADMIN')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getAdminRole = createAsyncThunk(
    'admins/getAdminRole',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('/admin/admin-role')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const changeAdminRole = createAsyncThunk(
    'admins/changeAdminRole',
    async ({ userId, adminRole }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put('/admin/admin-role', {
                userId,
                adminRole,
            })
            const { data } = response

            dispatch(
                openSnackbar({
                    open: true,
                    message: data.msg || 'Role changed.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            return data
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.msg || e.err || 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)

export const promteToAdmin = createAsyncThunk(
    'admins/promteToAdmin',
    async ({ userId }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put('/admin/promote', {
                userId,
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Promote to admin successfully.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            return data
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.msg || 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)

export const updateAdminUsers = createAsyncThunk(
    'admins/updateAdminUsers',
    async ({ userData }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put('/admin/users', {
                uid: userData.uid,
                data: {
                    coachingStatus: userData.coachingStatus,
                    coachingForAll: userData.coachingForAll,
                },
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Updated user successfully.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )

            return data
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e.msg || 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)
const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    adminLists: apiResponse,
    adminRoles: apiResponse,
    promoteAdmin: apiResponse,
    roleChange: apiResponse,
    coachingStatusChange: apiResponse,
}

const AdminSlice = createSlice({
    name: 'Client',
    initialState,
    reducers: {
        setAdminRoleLocally: (state: any, action: PayloadAction<any>) => {
            const { uid, role } = action.payload

            state.adminLists.data.map((item: any) => {
                if (item.uid === uid) {
                    item.adminRule = role
                }
                return item
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdmins.pending, (state: any) => {
                state.adminLists.isFetching = true
            })
            .addCase(
                getAdmins.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.adminLists.isFetching = false
                    state.adminLists.isSuccess = true
                    state.adminLists.data = payload
                }
            )
            .addCase(getAdmins.rejected, (state: any) => {
                state.adminLists.isFetching = false
            })
            .addCase(getAdminRole.pending, (state: any) => {
                state.adminRoles.isFetching = true
            })
            .addCase(
                getAdminRole.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.adminRoles.isFetching = false
                    state.adminRoles.isSuccess = true
                    state.adminRoles.data = payload?.adminRoles
                }
            )
            .addCase(getAdminRole.rejected, (state: any) => {
                state.adminRoles.isFetching = false
            })
            .addCase(promteToAdmin.pending, (state: any) => {
                state.promoteAdmin.isFetching = true
                state.promoteAdmin.isSuccess = false
            })
            .addCase(
                promteToAdmin.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.promoteAdmin.isFetching = false
                    state.promoteAdmin.isSuccess = true
                }
            )
            .addCase(promteToAdmin.rejected, (state: any) => {
                state.promoteAdmin.isFetching = false
                state.promoteAdmin.isSuccess = false
            })
            .addCase(changeAdminRole.pending, (state: any) => {
                state.roleChange.isFetching = true
                state.roleChange.isSuccess = false
            })
            .addCase(
                changeAdminRole.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.roleChange.isFetching = false
                    state.roleChange.isSuccess = true
                }
            )
            .addCase(changeAdminRole.rejected, (state: any) => {
                state.roleChange.isFetching = false
                state.roleChange.isSuccess = false
            })
            .addCase(updateAdminUsers.pending, (state: any) => {
                state.coachingStatusChange.isFetching = true
                state.coachingStatusChange.isSuccess = false
            })
            .addCase(
                updateAdminUsers.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.coachingStatusChange.isFetching = false
                    state.coachingStatusChange.isSuccess = true
                }
            )
            .addCase(updateAdminUsers.rejected, (state: any) => {
                state.coachingStatusChange.isFetching = false
                state.coachingStatusChange.isSuccess = false
            })
    },
})

export const { setAdminRoleLocally } = AdminSlice.actions
export const adminsSelector = (state: RootState) => state.admins
export default AdminSlice.reducer
